import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import CardWithBody from '../shared/panel/CardWithBody'
import ProgressBar from '../shared/progressBar/ProgressBar'
import { BWPanelContent, BWText } from 'src/libs/qb-brand-web-components'
import { percentageOfCompletion } from '../../util/number.helpers'
import { formatTicks } from '../../util/string.helpers'

const CommunityJackpot = ({
  t,
  milestoneProfile: { data },
  symbol
  // onShowPopup
}) => {
  const community = data.community?.[0] // @TODO: Using first array element for now, need to adjust if need many
  const threshold = community?.threshold
  const achieved = community?.achieved
  const value = formatTicks(threshold)
  const completed = percentageOfCompletion(achieved, threshold)
  const reward = community?.reward
  return (
    <CardWithBody
      title={t('reward-box.the-community-jackpot')}
      desc={
        <Trans
          i18nKey='reward-box.win-additional-points'
          values={{
            points: formatTicks(reward),
            symbol,
            value
          }}
        />
      }
      body={
        <BWPanelContent>
          <ProgressBar
            leftLabel={'0'}
            rightLabel={value}
            completed={completed}
            // onClick={
            //   completed >= 100
            //     ? () => {
            //         onShowPopup({
            //           title: t('reward-box.luck-is-on-your-side'),
            //           desc: t('reward-box.you-opened-community', {
            //             value
            //           }),
            //           value: reward
            //         })
            //       }
            //     : null
            // }
            info={
              <BWText
                label={
                  <Trans
                    i18nKey='reward-box.boxes-to-go-until-jackpot'
                    values={{
                      value: threshold ? formatTicks(threshold - achieved) : 0
                    }}
                  />
                }
              />
            }
          />
          <BWText
            size={12}
            label={t('reward-box.resets-after-every-reward', { value })}
            alignCenter
          />
        </BWPanelContent>
      }
    />
  )
}

export default memo(CommunityJackpot)
