import {
  mergeToNewObject,
  extractInputValueAndError,
  BW_ERROR_TEXT,
  BW_COGNITO_ERRORS
} from 'src/libs/qb-brand-web-components'

import { ERROR_TEXT, MESSAGE_TEXT } from '../../../constants/messages'
import {
  AUTH_LOGIN_ACTION,
  AUTH_SIGNUP_ACTION
} from './../../../constants/actions'

export const loginProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AUTH_LOGIN_ACTION.CHANGE_STATE:
      const { property, value } = payload
      return mergeToNewObject(state, { [property]: value })

    case AUTH_LOGIN_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case AUTH_LOGIN_ACTION.RESET_STATE:
      return INITIAL_STATE

    case AUTH_LOGIN_ACTION.ON_LOG_IN:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case AUTH_LOGIN_ACTION.ON_LOG_IN_FAILED:
      return mergeToNewObject(state, {
        error: extractLoginErrors(payload),
        isProcessing: false
      })

    case AUTH_LOGIN_ACTION.ON_LOG_IN_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false
      })

    case AUTH_SIGNUP_ACTION.ON_RESEND_EMAIL_SUCCESS:
      return mergeToNewObject(state, {
        error: {},
        success: { email: MESSAGE_TEXT.VERIFICATION_EMAIL_SENT }
      })

    case AUTH_SIGNUP_ACTION.ON_RESEND_EMAIL_FAILED:
      return mergeToNewObject(state, {
        success: {},
        error: { email: ERROR_TEXT.RESEND_EMAIL_VERIFICATION_FAILED }
      })

    case AUTH_SIGNUP_ACTION.ON_CONFIRM:
      return mergeToNewObject(state, {
        email: payload.email
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: {},
  success: {},
  isProcessing: false
}

const extractLoginErrors = (cognito) => {
  const code = cognito?.code
  switch (code) {
    case BW_COGNITO_ERRORS.USER_NOT_CONFIRMED_EXCEPTION:
      return {
        email: ERROR_TEXT.EMAIL_VERIFICATION_FAILED
      }
    case BW_COGNITO_ERRORS.NOT_AUTHORIZED_EXCEPTION:
      return {
        email: true,
        password: BW_ERROR_TEXT.ACCESS_DENIED
      }
    default:
      return {
        email: true,
        password: BW_ERROR_TEXT.SOMETHING_WENT_WRONG
      }
  }
}

export const LOGIN_PROFILE_DISPATCH = {
  EMAIL: 'email',
  PASSWORD: 'password',
  ERROR: 'error',
  SUCCESS: 'success'
}
