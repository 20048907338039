import { CUSTOM_BRAND_ID } from '../../constants/pointsExchange'

const getWalletAddressSelector = (state) => {
  const walletAddress =
    state.mainReducer.mainProfile.exchangeMemberships[
      CUSTOM_BRAND_ID.ETH_ADDRESS
    ]
  return walletAddress
    ? walletAddress
    : {
        id: null,
        membershipNumber: null
      }
}

export { getWalletAddressSelector }
