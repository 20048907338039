import React, { memo } from 'react'
import { faCircleQuestion, faGift } from '@fortawesome/pro-light-svg-icons'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { Trans } from 'react-i18next'

import {
  BWText,
  BWIcon,
  BWPanelContent,
  // formatCurrencyValue,
  BW_COLOR_ERROR,
  BWOutsideLink,
  BWAnimateRewards
} from 'src/libs/qb-brand-web-components'

import CardWithBody from '../shared/panel/CardWithBody'
import Button from '../shared/button/Button'
import {
  CLAIM_REWARD_BOX_LIMIT_PER_DAY,
  REWARD_BOX_PROCESS_STATUS
} from '../../constants/rewardBox'
import { formatTicks } from '../../util/string.helpers'
import RewardProcessingTimer from './RewardProcessingTimer'
import Tooltip from '../shared/tooltip/Tooltip'
import { isWalletConnected } from '../../brands/qbx_vip/utils/staking.helpers'

const OpenRewardBox = ({
  t,
  onOpenRewardBox,
  onOpenAnotherBox,
  rewardBoxStatus,
  // symbol,
  // amountReceived: { isETH, value },
  rewardBoxErr,
  endedRewardTimer,
  milestoneProfile: { achievedIndividual },
  isBlacklisted,
  setInitConnect
}) => {
  const depositErr = rewardBoxErr.deposit
  const isDeposited =
    rewardBoxStatus === REWARD_BOX_PROCESS_STATUS.DEPOSITED && !depositErr
  const isDepositing =
    rewardBoxStatus === REWARD_BOX_PROCESS_STATUS.DEPOSITING && !depositErr
  const crossedDailyLimit = achievedIndividual >= CLAIM_REWARD_BOX_LIMIT_PER_DAY
  return (
    <CardWithBody
      icon={faGift}
      title={
        isDeposited ? (
          t('reward-box.awesome-you-received')
        ) : (
          <BWIcon
            icon={faGift}
            faIconCss={{ fontSize: 72 }}
            shake={isDepositing}
          />
        )
      }
      desc={
        <>
          {isDeposited && (
            <BWText label={t('reward-box.opened-reward-box-desc')} />
          )}
          {!isDeposited && (
            <>
              <BWText
                label={
                  <Trans
                    i18nKey='reward-box.daily-reward-boxes-opened'
                    values={{
                      value: formatTicks(achievedIndividual),
                      total: formatTicks(CLAIM_REWARD_BOX_LIMIT_PER_DAY)
                    }}
                  />
                }
                customCss={{ marginRight: 5 }}
              />
              <Tooltip
                text={
                  <Trans
                    i18nKey='reward-box.daily-reward-boxes-disclaimer'
                    values={{
                      total: formatTicks(CLAIM_REWARD_BOX_LIMIT_PER_DAY)
                    }}
                  />
                }
              >
                <BWIcon icon={faCircleQuestion} />
              </Tooltip>
            </>
          )}
          {isDepositing && (
            <div>
              <RewardProcessingTimer endedTimer={endedRewardTimer} />
            </div>
          )}
        </>
      }
      body={
        <BWPanelContent>
          {isDeposited ? (
            <>
              {/* <BWPanelContent css={{ marginBottom: 20 }}>
                <BWText
                  alignCenter
                  label={
                    isETH
                      ? `$${formatCurrencyValue(Math.abs(value), 3)}`
                      : formatTicks(value)
                  }
                  size={32}
                  weight={800}
                />
                <BWText
                  alignCenter
                  label={isETH ? t('reward-box.worth-of-eth') : symbol}
                />
              </BWPanelContent> */}
              <Button
                label={t('reward-box.open-another-box')}
                onClick={onOpenAnotherBox}
              />
            </>
          ) : (
            <>
              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openConnectModal,
                  authenticationStatus,
                  mounted
                }) => {
                  const connected = isWalletConnected({
                    account,
                    chain,
                    authenticationStatus,
                    mounted
                  })
                  const handleConnectWallet = () => {
                    if (connected) {
                      onOpenRewardBox()
                    } else {
                      setInitConnect(true)
                      openConnectModal()
                    }
                  }
                  return (
                    <Button
                      label={t('reward-box.open-reward-box')}
                      onClick={handleConnectWallet}
                      disabled={
                        isDepositing || crossedDailyLimit || isBlacklisted
                      }
                    />
                  )
                }}
              </ConnectButton.Custom>
              {(isBlacklisted || crossedDailyLimit) && (
                <BWText
                  alignCenter
                  label={
                    isBlacklisted
                      ? t('reward-box.you-have-been-blocked')
                      : t('reward-box.daily-limit-reached')
                  }
                />
              )}
            </>
          )}
          <BWOutsideLink
            to='https://bridge.base.org/deposit'
            label={t('reward-box.bridge-to-base')}
            customCss={{ textDecoration: 'underline !important' }}
          />
          {depositErr && (
            <BWText
              color={BW_COLOR_ERROR}
              label={t(depositErr)}
              size={14}
              weight={500}
              alignCenter
            />
          )}
          {isDeposited && <BWAnimateRewards />}
        </BWPanelContent>
      }
    />
  )
}

export default memo(OpenRewardBox)
