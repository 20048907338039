import { isProduction, nextTargetTime } from 'src/libs/qb-brand-web-components'
import { FOUNDER_KEYS, MEMBER_TIERS, MEMBER_TIERS_KEY } from '../constants/app'
import { getNftCoverImage } from '../../../util/nft.helpers'

const isWalletConnected = ({
  mounted,
  authenticationStatus,
  account,
  chain
}) => {
  const ready = mounted && authenticationStatus !== 'loading'
  return (
    ready &&
    account &&
    chain &&
    (!authenticationStatus || authenticationStatus === 'authenticated')
  )
}

const disconnectMetamaskWallet = () => {
  localStorage.setItem('wagmi.io.metamask.disconnected', true)
  localStorage.setItem('wagmi.metaMask.disconnected', true)
}

const nextClaimTime = () => {
  // Every Wednesday at 2 PM UTC
  return nextTargetTime('Wednesday', 14)
}

const getClaimDashboardUrl = () => {
  return `https://tc${isProduction() ? '' : 'test'}.qiibeefoundation.org`
}

const getMemberTierMultiplierValue = (tier) => {
  return MEMBER_TIERS[tier] || 0
}

const getNftMultiplierValue = (nftCount) => {
  if (nftCount >= 1 && nftCount <= 5) {
    return FOUNDER_KEYS['1-5']
  } else if (nftCount >= 6 && nftCount <= 20) {
    return FOUNDER_KEYS['6-20']
  } else if (nftCount >= 21 && nftCount <= 50) {
    return FOUNDER_KEYS['21-50']
  } else if (nftCount >= 51 && nftCount <= 100) {
    return FOUNDER_KEYS['51-100']
  } else if (nftCount > 100) {
    return FOUNDER_KEYS['100+']
  }
  return 0
}

// @TODO: Change hard coding
const TIER_MULTIPLIER_MAP = isProduction()
  ? {
      1: MEMBER_TIERS_KEY.ASPIRING,
      5: MEMBER_TIERS_KEY.FAN,
      10: MEMBER_TIERS_KEY.INFLUENCER,
      15: MEMBER_TIERS_KEY.CHAMPION,
      20: MEMBER_TIERS_KEY.AMBASSADOR,
      25: MEMBER_TIERS_KEY.HONORARY
    }
  : {
      1: MEMBER_TIERS_KEY.ASPIRING,
      5: MEMBER_TIERS_KEY.FAN,
      10: MEMBER_TIERS_KEY.INFLUENCER,
      15: MEMBER_TIERS_KEY.CHAMPION,
      20: MEMBER_TIERS_KEY.AMBASSADOR,
      25: MEMBER_TIERS_KEY.HONORARY
    }

const getMemberTierMultiplierKey = (nfts) => {
  const nft = nfts.find((nft) => TIER_MULTIPLIER_MAP[nft.nftId])
  return nft
    ? {
        tier: TIER_MULTIPLIER_MAP[nft.nftId],
        icon: getNftCoverImage(nft?.metadata)
      }
    : {
        tier: MEMBER_TIERS_KEY.ASPIRING
      }
}

export {
  isWalletConnected,
  disconnectMetamaskWallet,
  nextClaimTime,
  getClaimDashboardUrl,
  getMemberTierMultiplierValue,
  getNftMultiplierValue,
  getMemberTierMultiplierKey
}
