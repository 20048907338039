import brandConfig from 'brandConfig'
import { REDIRECT_URL } from '../constants/navigation'
import {
  DYNAMIC_PAGE_REDIRECT_PATHS,
  NAVIGATION_TARGET_BLANK
} from '../constants/externalUrls'
import {
  buildBinanceShopUrl,
  buildCoinbaseShopUrl,
  isBrandIdBinance
} from './pointsExchange.helpers'
import { ALLCOLIBRI_IMPACT_REPORT_URL } from '../constants/app'
import { snakeCaseToDashCase } from './string.helpers'
import { SIGNED_PATH } from '../constants/api'
import customConfig from 'customConfig'

const buildRedeemUrlParams = (to) => {
  if (to) {
    const customRedeemShopUrl = customConfig.customRedeemShopUrl
    if (customRedeemShopUrl) {
      return {
        to: customRedeemShopUrl,
        ...NAVIGATION_BLANK_PARAMS
      }
    }
    const isCoeEnabled = brandConfig.features.enableCOERedemption
    return {
      to: isCoeEnabled ? coeRedemptionUrl() : to,
      ...(isCoeEnabled && NAVIGATION_BLANK_PARAMS)
    }
  }
  return {}
}

const buildViewShopUrl = (provider) => {
  if (provider.isCrypto) {
    return isBrandIdBinance(provider.brandId)
      ? buildBinanceShopUrl(provider.symbol)
      : buildCoinbaseShopUrl(provider.name)
  }
  return (
    provider?.metadata?.links?.websiteUrl ||
    provider?.redemptionCatalogueUrl ||
    provider?.loyaltyProgramUrl ||
    provider?.websiteUrl
  )
}

const coeBrandShopUrl = (provider) => {
  return `${REDIRECT_URL}/${DYNAMIC_PAGE_REDIRECT_PATHS.VIEW_SHOP}/${provider.brandId}`
}

const coeRedemptionUrl = () => {
  return `${REDIRECT_URL}/${DYNAMIC_PAGE_REDIRECT_PATHS.REDEEM}`
}

const getImpactReportUrl = () => {
  const baseUrl = ALLCOLIBRI_IMPACT_REPORT_URL
  if (brandConfig.features.enableRootImpactReport) {
    return baseUrl
  }
  return `${baseUrl}-${snakeCaseToDashCase(brandConfig.brandId)}`
}

const NAVIGATION_BLANK_PARAMS = {
  target: NAVIGATION_TARGET_BLANK,
  rel: 'noopener noreferrer'
}

const prependHttpsIfNotExists = (link) => {
  return link && link.indexOf('://') === -1 ? `https://${link}` : link
}

const buildSignedUrl = (path) => {
  return `${SIGNED_PATH}/${path}`
}

export {
  buildRedeemUrlParams,
  buildViewShopUrl,
  coeBrandShopUrl,
  coeRedemptionUrl,
  getImpactReportUrl,
  NAVIGATION_BLANK_PARAMS,
  prependHttpsIfNotExists,
  buildSignedUrl
}
