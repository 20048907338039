import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import {
  containsNoValues,
  emailRule,
  checkValidations,
  passwordRule,
  requiredRule
} from 'src/libs/qb-brand-web-components'
import { useLocation, useNavigate } from 'react-router-dom'

import Login from '../components/login/Login'
import {
  SignupConfirmation,
  SignupVerificationSuccess
} from '../components/popups/signUp'
import requireUnauth from '../hoc/requireUnauth'
import {
  changeLoginState,
  changeLoginInputAndErrorState,
  cleanLoginState,
  logIn,
  resendSignupEmail,
  socialMediaLogIn,
  cleanSignupState
} from '../store/actions/auth'
import { LOGIN_PROFILE_DISPATCH } from '../store/reducers/auth/login'
import { eventTrack } from '../util/gaTracking.helpers'
import {
  EVENT_TRACK_LABEL_BUTTON,
  EVENT_TRACK_LOGIN,
  EVENT_TRACK_LOGIN_SOCIAL
} from '../constants/eventTrack'
import { SIGNUP_URL } from '../constants/navigation'
import { getForceSignup, removeForceSignup } from '../util/local.helpers'
import {
  DEFAULT_CONTAINER_STATE,
  SIGNUP_PROCESS_STEP
} from '../constants/containerStates'
import { trackGTMDataLayer } from 'wrappers/trackGTMDataLayer'

const LoginContainer = ({
  loginProfile,
  changeLoginState,
  changeLoginInputAndErrorState,
  cleanLoginState,
  logIn,
  resendSignupEmail,
  socialMediaLogIn,
  cleanSignupState,
  signupProfile
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setPopupState(signupProfile.signupState)
  }, [signupProfile.signupState])

  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)

  const handleNoPopup = () => {
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
  }

  useEffect(() => {
    trackGTMDataLayer(location.pathname)
  }, [])

  useEffect(() => {
    if (getForceSignup()) {
      navigate(SIGNUP_URL)
    }
    // cleanLoginState()
    return () => {
      cleanLoginState()
      removeForceSignup()
      cleanSignupState()
    }
  }, [])

  const onFormSubmit = () => {
    const { email, password } = loginProfile
    const errors = checkValidations({
      email: { value: email, rules: [requiredRule, emailRule] },
      password: { value: password, rules: [requiredRule, passwordRule] }
    })
    if (containsNoValues(errors)) {
      logIn(email, password)
      eventTrack(
        EVENT_TRACK_LOGIN.CATEGORY,
        EVENT_TRACK_LOGIN.ACTION,
        EVENT_TRACK_LABEL_BUTTON
      )
    } else {
      changeLoginState(LOGIN_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const handleResendVerification = () => {
    const { email } = loginProfile
    resendSignupEmail(email)
  }

  const handleClosePopup = () => {
    cleanLoginState()
    cleanSignupState()
    handleNoPopup()
  }

  const handleSocialMediaLogin = (provider) => {
    socialMediaLogIn(provider)
    eventTrack(
      EVENT_TRACK_LOGIN_SOCIAL.CATEGORY,
      EVENT_TRACK_LOGIN_SOCIAL.ACTION,
      EVENT_TRACK_LABEL_BUTTON
    )
  }

  const renderPopup = () => {
    switch (popupState) {
      case SIGNUP_PROCESS_STEP.SIGNUP_VERIFIED:
        return <SignupVerificationSuccess onSubmit={handleClosePopup} t={t} />
      case SIGNUP_PROCESS_STEP.CONFIRM_VERIFICATION_FAILED:
      case SIGNUP_PROCESS_STEP.RESEND_VERIFICATION_SUCCESS:
      case SIGNUP_PROCESS_STEP.RESEND_VERIFICATION_FAILED:
        const {
          error: { confirm },
          signupState
        } = signupProfile
        const params = {
          title: t('sign-up.confirmation-failed'),
          desc: t(confirm?.message),
          showResend: confirm?.showResend,
          isError: true
        }
        return (
          <SignupConfirmation
            signupState={signupState}
            onResendVerification={handleResendVerification}
            onCancel={handleClosePopup}
            t={t}
            {...params}
          />
        )

      default:
        break
    }
  }

  return (
    <>
      <Login
        loginProfile={loginProfile}
        onFormSubmit={onFormSubmit}
        dispatchAction={changeLoginInputAndErrorState}
        onResendVerification={handleResendVerification}
        onSocialMediaLogin={handleSocialMediaLogin}
        t={t}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({ authReducer }) => {
  const { loginProfile, signupProfile } = authReducer
  return {
    loginProfile,
    signupProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeLoginState,
      changeLoginInputAndErrorState,
      cleanLoginState,
      logIn,
      resendSignupEmail,
      socialMediaLogIn,
      cleanSignupState
    },
    dispatch
  )

export default requireUnauth(
  connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
)
